<template>
  <v-card flat>
    <v-card-title>
      <v-icon left v-if="title && icon">{{ icon }}</v-icon>
      {{ title ? title : '' }}
    </v-card-title>
    <div class="__container mx-4">
      <input
        :id="`inputFile-${idTemporal}`"
        type="file"
        class="container__input"
        :accept="accepts"
        @change="convertToBase64"
      >
      <div class="container__text">
        <div>
          <v-icon
            x-large
            :color="files.length > 0 ? 'primary' : 'grey lighten-1'"
          >
            {{ files.length > 0 ? 'mdi-file-document' : 'mdi-file-upload-outline' }}
          </v-icon>
          <v-icon
            v-if="!multiple && files.length > 0"
            icon
            @click="clearComponent"
            color="red"
            small
            class="button__remove"
          >
            mdi-close-circle
          </v-icon>
        </div>
        <div v-if="!multiple && files.length > 0">
          {{ files[0].name }} 
        </div>
        <div v-else>
          Cargar archivo {{ extensions }}
        </div>
      </div>
    </div>
    <v-card-text v-if="endpointTemplate.length > 0">
      <div class="d-flex justify-end container__download">
        <a :href="endpointTemplate">
          Descargar Plantilla
        </a>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    typeFiles: {
      type: Array,
      default: () => [],
    },
    endpointTemplate: {
      type: String,
      default: '',
    },
    typeRead: {
      type: String,
      default: 'base64',
    },
    height: {
      type: Number,
      default: 120,
    }, 
  },
  data() {
    return {
      idTemporal: Math.round(9999 + Math.random() * 999),
      files: [],
      filesAccepts: {
        csv: '.csv',
        txt: '.txt',
        xml: '.xml',
        png: 'image/png',
        jpg: 'image/jpeg',
        pdf: 'application/pdf',
      },
    };
  },
  methods: {
    fileAlreadyExists(name) {
      const index = this.files.map((file) => file.name).indexOf(name);
      if (index === -1) return false;
      return true;
    },
    isValidateTypeFile(value) {
      let count = 0;
      this.typeFiles.forEach((typeFile) => {
        const newValueTyeFile = value.substring(
          value.length - typeFile.length,
          value.length
        );
        if (newValueTyeFile === typeFile) {
          count++;
        }
      });
      if (count > 0) return true;
      return false;
    },
    convertToBase64() {
      let inputFile = document.getElementById(`inputFile-${this.idTemporal}`);
      var selectedFile = inputFile.files;
      var selectedValue = inputFile.value;
      if (selectedFile.length > 0) {
        const index =
          selectedValue.indexOf('\\') >= 0
            ? selectedValue.lastIndexOf('\\')
            : selectedValue.lastIndexOf('/');
        let nameFile = selectedValue.substring(index);
        if (nameFile.indexOf('\\') === 0 || nameFile.indexOf('/') === 0)
          nameFile = nameFile.substring(1);

        if (this.fileAlreadyExists(nameFile.replace(/ /g, '_'))) {
          this.$notify({
            group: 'petition',
            type: 'warning',
            text: 'El archivo ya existe',
          });
          return false;
        }

        if (!this.isValidateTypeFile(nameFile)) {
          this.$notify({
            group: 'petition',
            type: 'warning',
            text: 'El archivo no es valido',
          });
          return false;
        }

        let fileToLoad = selectedFile[0];
        let fileReaderB64 = new FileReader();
        let fileReaderRaw = new FileReader();
        if (this.typeRead === 'base64') {
          fileReaderB64.readAsDataURL(fileToLoad);
          fileReaderB64.onload = (e) => {
            if (!this.multiple) {
              this.files = [];
            }
            this.files.push({
              id: -1,
              name: nameFile,
              urlFile: '',
              fileB64: e.target.result,
              blob: '',
            });
          };
        } else if (this.typeRead === 'raw') {
          fileReaderRaw.readAsText(fileToLoad);
          fileReaderRaw.onload = (e) => {
            if (!this.multiple) {
              this.files = [];
            }
            this.files.push({
              id: -1,
              name: nameFile,
              urlFile: '',
              raw: e.target.result,
              blob: '',
            });
          };
        } else if (this.typeRead === 'blob') {
          if (!this.multiple) {
            this.files = [];
          }
          this.files.push({
            id: -1,
            name: nameFile,
            urlFile: '',
            fileB64: '',
            blob: fileToLoad,
          });
        } else {
          if (!this.multiple) {
            this.files = [];
          }
          let newFile = {
            id: -1,
            name: nameFile,
            urlFile: '',
            fileB64: '',
            raw: '',
            blob: fileToLoad,
          };
          fileReaderB64.readAsDataURL(fileToLoad);
          fileReaderRaw.readAsText(fileToLoad);
          fileReaderB64.onload = (e) => {
            newFile.fileB64 = e.target.result;
          };
          fileReaderRaw.onload = (e) => {
            newFile.raw = e.target.result;
            this.files.push(newFile);
          };
        }
        document.getElementById(`inputFile-${this.idTemporal}`).value = '';
      }
    },
    clearComponent() {
      let inputFile = document.getElementById(`inputFile-${this.idTemporal}`);
      inputFile.value = '';
      this.files = [];
    },
    initStyle() {
      let container = document.getElementsByClassName('__container');
      let containerInput = document.getElementsByClassName('container__input');
      let containerText = document.getElementsByClassName('container__text');

      container[0].style.height = `${this.height}px`;
      containerInput[0].style.height = `${this.height}px`;
      containerText[0].style.height = `${this.height}px`;
    },
  },
  mounted() {
    this.initStyle();
  },
  computed: {
    extensions() {
      return this.typeFiles.join(', ');
    },
    accepts() {
      return this.typeFiles.map(type => this.filesAccepts[type]).join(', ');
    },
  },
  watch: {
    files(newFiles) {
      if (this.multiple)
        this.$emit('input', newFiles);
      else {
        let file = newFiles.length === 0 ? {} : newFiles[0];
        this.$emit('input', file);
      }
    },
  },
};
</script>

<style scoped>
.__container {
  height: 120px;
  border: 1px dashed #979797;
  border-radius: 0.5em;
  position: relative;
}
.container__input {
  position: absolute;
  width: 100%;
  height: 120px;
  z-index: 10;
  opacity: 0;
  cursor: pointer;
}
.container__text {
  /* top: 25%; */
  padding-top: 25px;
  position: relative;
  width: 100%;
  height: 120px;
  font-weight: bold;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 10px;
}
.container__download {
  font-size: 10px;
}
input:hover + label,
input:focus + label {
  transform: scale(1.02);
  outline: none;
}
.button__remove {
  z-index: 20;
}
</style>
<template>
  <div>
    <Loader :loading="loading" />
    <div
      v-if="products.length > 0"
      class="d-flex justify-end"
    >
      <v-btn
        @click="saveProducts"
        small
        color="success"
      >
        Guardar
      </v-btn>
    </div>
    <v-alert
      v-if="products.length === 0"
      outlined
      type="info"
      text
    >
      Agregar un archivo <b>CSV</b> con productos y skus con la siguiente
      <a :href="urlTemplate" target="_blank" rel="noopener noreferrer">Plantilla</a>
    </v-alert>
    <ComponentFiles
      v-if="products.length === 0"
      ref="componentFile"
      v-model="fileCsv"
      icon="mdi-upload"
      :type-files="['csv']"
      type-read="raw"
    />
    <v-data-table
      v-else
      :headers="headers"
      :items="products"
      class="elevation-0"
      disable-pagination
      hide-default-footer
      fixed-header
    >
      <template v-slot:item.product="{ item }">
        <input v-model="item.product" class="input__simple" />
      </template>
      <template v-slot:item.sku="{ item }">
        <input v-model="item.sku" class="input__simple" />
      </template>
      <template v-slot:item.min_stock="{ item }">
        <input v-model="item.min_stock" class="input__simple" type="number" />
      </template>
      <template v-slot:item.max_stock="{ item }">
        <input v-model="item.max_stock" class="input__simple" type="number" />
      </template>
      <template v-slot:item.box_qty="{ item }">
        <input v-model="item.box_qty" class="input__simple" type="number" />
      </template>
      <template v-slot:item.brand="{ item }">
        <input v-model="item.brand" class="input__simple" />
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn @click="removeItem(item)" small color="red" icon>
          <v-icon>mdi-trash-can</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import ProductServices from '@/store/services/inventory/ProductServices';
import ComponentFiles from '@/components/commons/ComponentFiles';
import { uuid } from '@/constants/helpers';
import { mapGetters } from 'vuex';
export default {
  components: {
    ComponentFiles,
  },
  data() {
    return {
      products: [],

      fileCsv: {},
      loading: false,

      urlTemplate: `${process.env.VUE_APP_URL}/storage/templates-csv/products-template.csv`
    };
  },
  methods: {
    getProductsArray(str, delimiter = ',') {
      const strRemoveR = str.replace(/\r/g, '');
      const headers = strRemoveR.slice(0, strRemoveR.indexOf('\n')).split(delimiter);

      // slice from \n index + 1 to the end of the text
      // use split to create an array of each csv value row
      let rows = strRemoveR.slice(strRemoveR.indexOf('\n') + 1).split('\n');
      // Map the rows
      // split values from each row into an array
      // use headers.reduce to create an object
      // object properties derived from headers:values
      // the object passed as an element of the array
      const arr = rows.map(function (row) {
        const values = row.split(delimiter);
        const el = headers.reduce(function (object, header, index) {
          object[header] = values[index];
          return object;
        }, {});
        return el;
      });

      // return the array
      return arr.map(product => ({
        ...product,
        uuid: uuid(),
      }));
    },
    removeItem(item) {
      const index = this.products.findIndex((product) => product.uuid === item.uuid);
      this.products.splice(index, 1);
    },
    async saveProducts() {
      try {
        this.loading = true;
        await ProductServices.storeBulk(this.user.archon.id, {
          products: this.products,
        });
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
    }),
    headers() {
      return [
        {
          text: 'Producto',
          sortable: false,
          value: 'product',
        },
        {
          text: 'Sku',
          sortable: false,
          value: 'sku',
        },
        {
          text: 'Min. Stock ',
          value: 'min_stock',
          sortable: false,
        },
        {
          text: 'Max. Stock',
          value: 'max_stock',
          sortable: false,
        },
        {
          text: 'Cant. Caja',
          value: 'box_qty',
          sortable: false,
        },
        {
          text: 'Marca',
          value: 'brand',
          sortable: false,
        },
        { text: 'Actions', value: 'actions', sortable: false, width: '10%' },
      ];
    },
  },
  watch: {
    fileCsv() {
      let data = this.fileCsv.raw;
      this.products = this.getProductsArray(data);
      console.log(this.products);
    },
  },
}
</script>

<style scoped src="@/styles/InputSimple.css" />
